import axios from "axios";
import * as actionTypes from "./types";
import * as pageActions from "../page/actions";
import * as toastActions from "../../components/common/toastify";
import { BACKEND_SERVER_URL } from "../../config";

export const getContactUsData = () => async (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .get(BACKEND_SERVER_URL + "/getAllContactedDetail")
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.GET_CONTACT_US_DATA,
          payload: data.contactedList,
        });
      } else {
        toastActions.Error("SomeThing Went Wrong!");
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
