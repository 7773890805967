import React, { useEffect, useState } from "react";
import ClusterMaker from "./marker";

export default function gMap({ p }) {
  const [places, setPlaces] = useState([]);

  console.log("location getting p:", p);

  const setDefaultLocations = (data, id) => {
    data.forEach((result) => {
      if (id && parseInt(id, 10) !== parseInt(result.locationId, 10)) {
        result.show = false;
      }
    });
    return data;
  };

  useEffect(() => {
    if (p.length > 0) {
      p = setDefaultLocations(p, "0");
    }
    if (p && JSON.stringify(places) !== JSON.stringify(p)) {
      setPlaces(p);
    }
  }, [p]);

  // onChildClick callback can take two arguments: key and childProps
  const onChildClickCallback = (key, childProps) => {
    const defaultPlaces = setDefaultLocations(places, key);
    const updatePlaces = [...defaultPlaces];
    const index = updatePlaces.findIndex(
      (data) => parseInt(data.locationId, 10) === parseInt(key, 10)
    );
    updatePlaces[index].show = !updatePlaces[index].show;
    setPlaces(updatePlaces);
  };

  return (
    <ClusterMaker places={places} onChildClickCallback={onChildClickCallback} />
  );
}
