import * as actionTypes from "./types";

export const setPageLoading = () => {
  return {
    type: actionTypes.PAGE_LOADING,
  };
};

export const clearPageLoading = () => {
  return {
    type: actionTypes.PAGE_LOADED,
  };
};
