import React from "react";
import Loader from "react-loader-spinner";
const LoaderComponent = () => {
  return (
    <>
      <Loader
        type="Puff"
        color="#619F32"
        height={100}
        width={100}
        style={{
          textAlign: "center",
        }}
      />
    </>
  );
};

export default LoaderComponent;
