import * as actionTypes from "./types";

const INITIAL_STATE = {
  dashboardData: {},
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA:
      return { ...state, dashboardData: action.payload, loading: false };
    default:
      return state;
  }
};
export default dashboardReducer;
