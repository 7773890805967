import React from "react";
import Nav from "../Nave/nav";
import Footer from "../Footer/Footer";
import Form from "./components/Form";

export default () => {
  return (
    <div>
      {/* //Start Nave */}
      <Nav />
      {/* //End Nave */}

      {/* //start Form */}
      <Form />
      {/* //End Form */}
      {/* //Start Footer */}
      <Footer />
      {/* //End Footer */}
    </div>
  );
};
