import React from "react";

// Marker component
export const Marker = ({ text }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "50px",
        height: "50px",
        top: "-25px",
        left: "-25px",
      }}
    >
      <img src={text} alt="marker" />
    </div>
  );
};
