import React from "react";

export default function registerform() {
  return (
    <div>
      <div className="d-flex flex-column-fluid flex-center">
        <div className="login-form login-signin">
          {/* begin::Form */}
          <img
            src="images/ic-logo.svg"
            alt="logo"
            className="envision-red-logo"
          />
          <form
            className="login-form form fv-plugins-bootstrap fv-plugins-framework"
            noValidate="novalidate"
            id="kt_login_signin_form"
          >
            <div className="pb-7 pt-lg-0 pt-5">
              <h3 className="welcome-title">Sign Up</h3>
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                type="text"
                name="userfirst"
                autoComplete="off"
                placeholder="First Name"
              />
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                type="text"
                name="userlast"
                autoComplete="off"
                placeholder="Last Name"
              />
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                type="text"
                name="email"
                autoComplete="off"
                placeholder="Enter Email Address"
              />
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                id="password"
                type="password"
                name="password"
                autoComplete="off"
                placeholder="Enter Password"
              />
              <span className="show">SHOW</span>
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                type="text"
                name="phone_no"
                autoComplete="off"
                placeholder="Enter Phone Number"
              />
            </div>
            <div className="pb-lg-0 pb-5">
              <button
                type="button"
                id="kt_login_signin_submit"
                className="gilroy-medium btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              >
                SIGN UP
              </button>
            </div>
          </form>
          {/* end::Form */}
        </div>
      </div>
    </div>
  );
}
