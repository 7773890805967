// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import { useHistory } from "react-router-dom";

// import { login } from "../../../../store/auth/actions";
// const LoginForm = () => {
//   const [showPassword, setShowPassword] = useState(false);
//   const { loading } = useSelector((state) => state.page);

//   const dispatch = useDispatch();
//   const history = useHistory();
//   const loginSchema = Yup.object().shape({
//     userName: Yup.string().required("* Username is required"),
//     password: Yup.string().required("* Password is required"),
//   });
//   const handleShowPassword = () => {
//     setShowPassword(!showPassword);
//   };
//   const handleSubmit = (values) => {
//     dispatch(login(values, history));
//   };
//   return (
//     <div>
//       <div className="d-flex flex-column-fluid flex-center">
//         <div className="login-form login-signin">
//           {/* begin::Form */}
//           <img
//             src="images/xp_eats.svg"
//             alt="logo"
//             className="envision-red-logo"
//             width="200px"
//           />
//           <Formik
//             initialValues={{
//               userName: "",
//               password: "",
//             }}
//             validationSchema={loginSchema}
//             onSubmit={handleSubmit}
//           >
//             {({
//               values,
//               errors,
//               touched,
//               handleChange,
//               handleBlur,
//               handleSubmit,
//             }) => (
//               <form
//                 onSubmit={handleSubmit}
//                 className="login-form form fv-plugins-bootstrap fv-plugins-framework"
//                 noValidate="novalidate"
//                 id="kt_login_signin_form"
//               >
//                 <div className="pb-7 pt-lg-0 pt-5">
//                   <h3 className="welcome-title">Login Form</h3>
//                 </div>
//                 <div className="form-group fv-plugins-icon-container mx-1">
//                   <input
//                     className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
//                     type="text"
//                     name="userName"
//                     autoComplete="off"
//                     required
//                     placeholder="Enter Email Address"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={values.userName}
//                   />
//                 </div>
//                 {errors.userName && touched.userName ? (
//                   <div style={{ color: "red" }}>{errors.userName}</div>
//                 ) : null}
//                 <div className="form-group fv-plugins-icon-container mx-1">
//                   <input
//                     className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
//                     id="password"
//                     type={showPassword ? "text" : "password"}
//                     name="password"
//                     required
//                     autoComplete="off"
//                     placeholder="Enter Password"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={values.password}
//                   />
//                   <span
//                     style={{ cursor: "pointer", fontSize: "14px" }}
//                     className="show"
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {showPassword ? "HIDE" : "SHOW"}
//                   </span>
//                 </div>
//                 {errors.password && touched.password ? (
//                   <div style={{ color: "red" }}>{errors.password}</div>
//                 ) : null}
//                 <div className="pb-lg-0 pb-5">
//                   <button
//                     type="submit"
//                     id="kt_login_signin_submit"
//                     className="gilroy-medium btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
//                   >
//                     {loading ? "Loading" : "SIGN IN"}
//                   </button>
//                 </div>
//               </form>
//             )}
//           </Formik>

//           {/* end::Form */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginForm;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { login } from "../../../../store/auth/actions";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { loading } = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const history = useHistory();

  const loginSchema = Yup.object().shape({
    userName: Yup.string().required("* Username is required"),
    password: Yup.string().required("* Password is required"),
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values) => {
    dispatch(login(values, history)); // Pass the values and history to the login action
  };

  return (
    <div>
      <div className="d-flex flex-column-fluid flex-center">
        <div className="login-form login-signin">
          <img
            src="images/xp_eats.svg"
            alt="logo"
            className="envision-red-logo"
            width="200px"
          />
          <Formik
            initialValues={{
              userName: "",
              password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="login-form form fv-plugins-bootstrap fv-plugins-framework"
                noValidate="novalidate"
                id="kt_login_signin_form"
              >
                <div className="pb-7 pt-lg-0 pt-5">
                  <h3 className="welcome-title">Login Form</h3>
                </div>
                <div className="form-group fv-plugins-icon-container mx-1">
                  <input
                    className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                    type="text"
                    name="userName"
                    autoComplete="off"
                    required
                    placeholder="Enter Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                  />
                </div>
                {errors.userName && touched.userName ? (
                  <div style={{ color: "red" }}>{errors.userName}</div>
                ) : null}
                <div className="form-group fv-plugins-icon-container mx-1">
                  <input
                    className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    required
                    autoComplete="off"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span
                    style={{ cursor: "pointer", fontSize: "14px" }}
                    className="show"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? "HIDE" : "SHOW"}
                  </span>
                </div>
                {errors.password && touched.password ? (
                  <div style={{ color: "red" }}>{errors.password}</div>
                ) : null}
                <div className="pb-lg-0 pb-5">
                  <button
                    type="submit"
                    id="kt_login_signin_submit"
                    className="gilroy-medium btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                  >
                    {loading ? "Loading" : "SIGN IN"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
