import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../common/Loader";
import { getAllVAR } from "../../../store/var/actions";
export default function Body() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.page);
  const { varList } = useSelector((state) => state.var);
  useEffect(() => {
    dispatch(getAllVAR());
  }, []);

  return (
    <div>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div className="page-custom">
          <div className="page-4-container">
            {varList &&
              varList.map((dat, index) => {
                return (
                  <div key={index} className="user-detail">
                    <div className="user-img">
                      {dat.image ? (
                        <img src={dat.image} alt="user" />
                      ) : (
                        <img src="/images/ic_var.png" alt="user" />
                      )}
                    </div>
                    <div className="user-description ps-3">
                      <div className="user-top-details">
                        <div className="user-t">
                          {dat.firstName} {dat.lastName}
                        </div>
                        {/* <div className="user-icon">
                          <i className="fa fa-ellipsis-v" aria-hidden="true" />
                        </div> */}
                      </div>
                      <div className="user-specs">
                        <div className="user-email">{dat.emailId}</div>
                        {/* <div className="user-price">Earn $20.00</div> */}
                      </div>
                      <div className="user-specs">
                        <div className="user-email">{dat.contactNumber}</div>
                        {/* <div className="user-price">24 Locations</div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
