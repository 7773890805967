import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

// importing redux
import pageReducer from "./page/reducers";
import authReducer from "./auth/reducers";
import dashboardReducer from "./dashboard/reducer";
import contactReducer from "./contactUs/reducer";
import varReducer from "./var/reducer";
import locationReducer from "./location/reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  page: pageReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  contact: contactReducer,
  var: varReducer,
  location: locationReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
