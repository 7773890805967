// import React from "react";
// import { Modal } from "react-bootstrap";

// export default ({ show, handleClose, varDetail }) => {
//   return (
//     <Modal show={show} onHide={handleClose} centered>
//       <Modal.Body>
//         <div className="var_detail_modal model-body">
//           <div className="modal_upper_section'">
//             <div className="modal_image_section">
//               <img
//                 className="user-image"
//                 src={
//                   varDetail.userImage &&
//                   varDetail.userImage !== "" &&
//                   varDetail.userImage !== "image"
//                     ? varDetail.userImage
//                     : "images/ic_var.png"
//                 }
//                 alt="user"
//               />
//               <div className="user-details">
//                 <div className="flex">
//                   <span className="">{varDetail.fullName ?? ""}</span>
//                 </div>
//               </div>
//             </div>
//             <div className="modal_information_section">
//               <div className="user-details-t">
//                 <span className="flex-t">{varDetail.userName ?? ""}</span>
//               </div>
//               <div className="user-details-t">
//                 <span className="flex-t">{varDetail.emailId ?? ""}</span>
//               </div>
//               <div className="user-details-t">
//                 <span className="flex-call">
//                   {varDetail.contactNumber ?? ""}
//                 </span>
//               </div>
//             </div>
//             <div
//               className="modal_location_section"
//               style={{ textAlign: "right", float: "right" }}
//             >
//               <span className="location">
//                 {varDetail.locationsList && varDetail.locationsList.length}{" "}
//                 Locations
//               </span>
//             </div>
//           </div>
//           <div className="user-details-t">
//             <div className="user-details-t">
//               <span className="flex-p">{varDetail.description ?? ""}</span>
//             </div>
//             <div className="user-details-t">
//               <a href={varDetail.youtubeLink ?? ""} className="flex-link">
//                 {varDetail.youtubeLink ?? ""}
//               </a>
//             </div>
//             <iframe
//               title="YouTube Video"
//               width="560"
//               height="315"
//               src={varDetail.youtubeLink ?? ""}
//               frameBorder="0"
//               allowFullScreen
//             ></iframe>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

import React from "react";
import { Modal } from "react-bootstrap";
import { getYoutubeId } from "../../../utils/common";

export default ({ show, handleClose, varDetail }) => {
  console.log(varDetail);
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="var_detail_modal model-body">
          <div className="modal_upper_section">
            <div className="modal_image_section">
              <img
                className="user-image"
                src={
                  varDetail.userImage &&
                  varDetail.userImage !== "" &&
                  varDetail.userImage !== "image"
                    ? varDetail.userImage
                    : "images/ic_var.png"
                }
                alt="user"
              />

              <div className="user-details">
                <div className="flex">
                  <span className="fw-bold ">{varDetail.fullName ?? ""}</span>
                </div>
              </div>
            </div>

            <div className="modal_information_section">
              <div className="user-details-t">
                <span className="flex-t">{varDetail.userName ?? ""}</span>
              </div>
              <div className="user-details-t">
                <span className="flex-t">{varDetail.emailId ?? ""}</span>
              </div>
              {/* <div className="user-details-t">
                          <span className="flex-call">{varDetail.contactNumber??""}</span>
                      </div> */}
            </div>
            <div
              className="modal_location_section"
              style={{ textAlign: "right", float: "right" }}
            >
              <span className="location">
                {varDetail.locationsList && varDetail.locationsList.length}{" "}
                Locations
              </span>
            </div>
          </div>
          <div className="user-details-t">
            <div className="user-details-t my-2">
              <span className="flex-p">{varDetail.description ?? ""}</span>
            </div>
            <div className="user-details-t">
              <iframe
                width="100%"
                height="350px"
                style={{ borderRadius: "16px" }}
                src={
                  varDetail.youtubeLink
                    ? `https://www.youtube.com/embed/${getYoutubeId(
                        varDetail.youtubeLink
                      )}`
                    : "https://www.youtube.com/watch?v=jAMFX1VBJ9Y"
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
