import React from "react";
import { Route } from "react-router-dom";

import Dashboard from "./Dashboard/Index";
import Location from "./Location/Index";
import AdminLocationListView from "./AdminLocationListView";
import AddLocation from "./AddLocation/Index";
import AdminLocationDetails from "./AdminLocationDetails/Index";
import AdminLocationStatus from "./AdminLocationStatus/index";
import ContactUs from "./ContactUs/Index";
import AdminVarList from "./AdminVarList/Index";
import AdminAddVar from "./AdminAddVar/Index";

export default () => {
  return (
    <React.Fragment>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/Location" component={AdminLocationListView} />
      <Route exact path="/AddLocation" component={AddLocation} />
      <Route exact path="/ContactUs" component={ContactUs} />
      <Route exact path="/AdminVarList" component={AdminVarList} />
      <Route exact path="/AdminAddVar" component={AdminAddVar} />
      <Route exact path="/Location-:userId" component={Location} />
      <Route
        exact
        path="/AdminLocationDetails"
        component={AdminLocationDetails}
      />
      <Route
        exact
        path="/AdminLocationStatus"
        component={AdminLocationStatus}
      />
    </React.Fragment>
  );
};
