import axios from "axios";

export const setDefaultHeader = () => {
  axios.interceptors.request.use(function (config) {
    if (config.method === "get") {
      config.headers["Content-Type"] = "application/json";
      config.data = {};
    }
    return config;
  });
};
