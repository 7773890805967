import * as actionTypes from "./types";

const initialState = {
  locationList: [],
  statusesList: [],
  locationDetail: {},
  varDetailList: [],
  thirdPartyLocations: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USER_LOCATIONS:
      return {
        ...state,
        locationList: action.payload,
      };
    case actionTypes.SET_STATUSES_LIST:
      return {
        ...state,
        statusesList: action.payload,
      };
    case actionTypes.SET_LOCATION_DETAIL:
      return {
        ...state,
        locationDetail: action.payload,
      };
    case actionTypes.SET_VAR_DETAIL_LOCATION_LIST:
      return {
        ...state,
        varDetailList: action.payload,
      };
    case actionTypes.DELETE_USER_LOCATION: {
      const updatedLocationList = state.locationList.filter(
        (data) => parseInt(data.locationId, 10) !== parseInt(action.payload, 10)
      );
      return {
        ...state,
        locationList: updatedLocationList,
      };
    }
    case actionTypes.SET_THIRD_PARTY_LOCATIONS:
      return {
        ...state,
        thirdPartyLocations: action.payload,
      };
    default:
      return state;
  }
}
