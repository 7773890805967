import React, { useState, useEffect } from "react";
import Nav from "../Nave/nav";
import Footer from "../Footer/Footer";
import Body from "./components/Body";
import { useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  getStatusByType,
  getLocationDetail,
  updateLocationStatus,
} from "../../store/location/actions";

import ApprovedStatusForm from "./components/approvedStatusForm";

export default function Index() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { statusesList, locationDetail } = useSelector(
    (state) => state.location
  );
  const [show, setShow] = useState(false);

  const handleClose = () => {
    const selectedStatus = statusesList.filter((data) => data.id === 3);
    dispatch(
      updateLocationStatus(
        {
          ...locationDetail,
          statusId: 3,
          thirdpartyLocationId: locationDetail.locationThirdpartyId,
          callFromThirdParty: true,
          statusName: selectedStatus[0].name,
        },
        setShow
      )
    );
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    const locationId = new URLSearchParams(location.search).get("locationId");
    const requestPacketStatus = {
      type: "tabletLocationStatus",
    };
    const requestPacketLocation = {
      locationId,
    };
    dispatch(getStatusByType(requestPacketStatus));
    dispatch(getLocationDetail(requestPacketLocation));
  }, []);

  const onStatusChangeHandler = (e) => {
    const { value } = e.target;
    const selectedStatus = statusesList.filter(
      (data) => data.id === Number(value)
    );
    console.log(`selected Status: `, selectedStatus);
    if (Number(value) === 3 && Number(value) !== locationDetail.statusId) {
      handleShow();
    } else if (Number(value) !== locationDetail.statusId) {
      dispatch(
        updateLocationStatus(
          {
            ...locationDetail,
            thirdpartyLocationId: locationDetail.locationThirdpartyId,
            callFromThirdParty: true,
            statusId: Number(value),
            statusName: selectedStatus[0].name,
          },
          setShow
        )
      );
    }
  };

  const statusFormSubmitHandler = (tablefee, tax, range) => {
    const selectedStatus = statusesList.filter((data) => data.id === 5);
    dispatch(
      updateLocationStatus(
        {
          ...locationDetail,
          thirdpartyLocationId: locationDetail.locationThirdpartyId,
          callFromThirdParty: true,
          statusName: selectedStatus[0].name,
          statusId: 5,
          tablefee,
          tax,
          range,
        },
        setShow
      )
    );
  };

  return (
    <div>
      {/* Start Nav */}
      <Nav />
      {/* End Nav */}
      <Body
        statusesList={statusesList}
        locationDetail={locationDetail}
        onStatusChangeHandler={onStatusChangeHandler}
      />
      {/* Start Footer */}
      <Footer />
      {/* End Footer */}

      <ApprovedStatusForm
        show={show}
        handleClose={handleClose}
        statusFormSubmitHandler={statusFormSubmitHandler}
      />
    </div>
  );
}
