import axios from "axios";
import { setDefaultHeader } from "../../utils/setDefaultHeader";
import * as actionTypes from "./types";
import * as pageActions from "../page/actions";
import * as toastActions from "../../components/common/toastify";
import { BACKEND_SERVER_URL } from "../../config";
import { json } from "body-parser";

export const getUserLocations = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  console.log(reqPacket);

  axios
    .post(BACKEND_SERVER_URL + "/getUserLocations", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_USER_LOCATIONS,
          payload: data.locationsList,
        });
      } else {
        toastActions.Error(
          "SomeThing Went Wrong! While fetching user locations."
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getStatusByType = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);

  axios
    .post(BACKEND_SERVER_URL + "/getStatusByType", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_STATUSES_LIST,
          payload: data.statusesList,
        });
      } else {
        toastActions.Error(
          "SomeThing Went Wrong! While fetching statuses list."
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getAllLocations = () => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(false);

  axios
    .get(BACKEND_SERVER_URL + "/getAllLocations")
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(res.data);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_USER_LOCATIONS,
          payload: data.locationList,
        });
      } else {
        toastActions.Error("SomeThing Went Wrong! While fetching locations!");
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getLocationDetail = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);

  axios
    .post(BACKEND_SERVER_URL + "/getLocationDetail", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_LOCATION_DETAIL,
          payload: data,
        });
      } else {
        toastActions.Error(
          "SomeThing Went Wrong! While fetching location detail!"
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const updateLocationStatus = (reqPacket, hideModel) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  console.log("req packet:", reqPacket);
  axios
    .post(BACKEND_SERVER_URL + "/updateLocationStatus", reqPacket)
    .then((res) => {
      const { resultCode } = res.data;
      console.log(res.data);
      if (resultCode === "200") {
        dispatch(
          getLocationDetail({
            locationId: reqPacket.locationId,
          })
        );
        toastActions.Success("Successfully updated location status!");
        hideModel(false);
      } else {
        toastActions.Error(
          "SomeThing Went Wrong! While fetching location detail!"
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getLocationsByUserId = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  console.log(reqPacket);
  axios
    .post(BACKEND_SERVER_URL + "/getLocationsByUserId", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(res.data);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_USER_LOCATIONS,
          payload:
            data && data.locationList && data.locationList.length
              ? data.locationList
              : [],
        });
      } else {
        toastActions.Error(
          "SomeThing Went Wrong! While fetching user locations."
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getVARLocDetailList = () => (dispatch) => {
  dispatch(pageActions.setPageLoading());

  axios
    .post(BACKEND_SERVER_URL + "/getVARLocDetailList", {
      userId: 1,
      userDetail: "admin",
    })
    .then((res) => {
      const { resultCode, message, data } = res.data;
      console.log(resultCode);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_VAR_DETAIL_LOCATION_LIST,
          payload: data.varDetailList,
        });
      } else {
        toastActions.Error(message);
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error("Something went wrong! please try again.");
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const deleteLocation = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  console.log(reqPacket, "delete dispatch here");
  axios
    .post(BACKEND_SERVER_URL + "/deleteLocation", reqPacket)
    .then((res) => {
      const { resultCode } = res.data;
      if (resultCode === "200") {
        toastActions.Success("Successfully deleted!");
        dispatch({
          type: actionTypes.DELETE_USER_LOCATION,
          payload: reqPacket.locationId,
        });
      } else {
        toastActions.Error(
          "SomeThing Went Wrong! While fetching user locations."
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getThirdPartyLocations = (thirdpartyIds) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  const reqPacket = {
    thirdpartyIds,
  };
  axios
    .post(BACKEND_SERVER_URL + "/getThirdPartyLocations", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_THIRD_PARTY_LOCATIONS,
          payload: data,
        });
      } else {
        toastActions.Error(
          "Something Went Wrong! While fetching user locations."
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const addLocation = (reqPacket, history) => (dispatch) => {
  console.log(reqPacket);
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  axios
    .post(BACKEND_SERVER_URL + "/addLocation", reqPacket)
    .then((res) => {
      const { resultCode } = res.data;
      if (resultCode === "200") {
        toastActions.Success("Location Successfully Added!");
        history.push("/Location");
      } else {
        toastActions.Error(
          "SomeThing Went Wrong! While fetching user locations."
        );
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
