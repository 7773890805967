import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../store/auth/actions";

export default function nav() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  console.log(user);
  const handleLogout = () => {
    dispatch(logoutUser());
  };
  return (
    <div>
      <nav className="flow-navbar navbar navbar-expand-lg navbar-light bg-light">
        <div className="max-width">
          <Link className="navbar-brand" to="/dashboard">
            <img src="images/xp_eats.svg" alt="Envision" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse header-collapsible-link"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="/dashboard">
                  DASHBOARD <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Location">
                  LOCATIONS
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/AddLocation">
                  ADD NEW LOCATION
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/ContactUs">
                  CONTACT US
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/AdminLocationStatus">
                  TABLET STATUS
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/AdminVarList">
                  VAR LIST
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/AdminAddVar">
                  CREATE VAR
                </Link>
              </li>
            </ul>
            <div className="header-right-part">
              {/* <span>
                <Link to="#">
                  <img src="images/ic_search.svg" alt="ic_search" />
                </Link>
              </span> */}
              {/* <span className="position-relative cart-container">
                <span className="counter">8</span>
                <Link to="#">
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </Link>
              </span> */}
              {/* <span>
                <Link to="#">
                  <img src="images/053-canada.svg" alt="053-canada" />
                </Link>
              </span> */}
              <span className="user-name">
                Hi, <span className=" fw-bold">{user.userName}</span>
              </span>
              {/* <span className="right-user">
                <Link to="#">
                  <img src="images/032-boy-13.svg" alt="032-boy-13" />
                </Link>
              </span> */}
              <span className="">
                <span
                  className="btn btn-success text-white"
                  onClick={handleLogout}
                >
                  Logout
                </span>
              </span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
