export const publicRoutes = ["/login", "/register"];
export const privateRoutes = [
  "/",
  "/dashboard",
  "/Location",
  "/AddLocation",
  "/ContactUs",
  "/AdminVarList",
  "/AdminAddVar",
  "/Location-:userId",
  "/AdminLocationDetails",
  "/AdminLocationStatus",
];
