import React from "react";
import Banner from "./components/banner";
import Registerform from "./components/registerform";

const Index = () => {
  return (
    <div>
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        {/* begin::Aside */}
        <Banner />
        {/* begin::Aside */}
        {/* begin::Content */}
        <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          {/* begin::Signin */}
          <Registerform />
          {/* end::Signin */}
        </div>
        {/* end::Content */}
      </div>
    </div>
  );
};
export default Index;
