import React from "react";
import Nav from "../Nave/nav";
import Footer from "../Footer/Footer";
import Body from "./components/Body";

export default function Index() {
  return (
    <div>
      {/* Start Nav  */}
      <Nav />
      {/* End Nav */}

      {/* Start Body */}
      <Body />
      {/* End Body */}

      {/* Start Footer */}
      <Footer />
      {/* End Footer */}
    </div>
  );
}
