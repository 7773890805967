import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { validateIsNumber } from "../../../utils/common";

export default ({ show, handleClose, statusFormSubmitHandler }) => {
  const [tablefee, setTablefee] = useState("");
  const [tax, setTax] = useState("");
  const [range, setRange] = useState("");

  useEffect(() => {
    if (!show) {
      setTablefee("");
      setTax("");
      setRange("");
    }
  }, [show]);

  const onChangeHandler = (name) => (e) => {
    const { value } = e.target;
    if (validateIsNumber(value) && name === "tablefee") {
      setTablefee(value);
    } else if (validateIsNumber(value) && name === "tax") {
      setTax(value);
    } else if (validateIsNumber(value) && name === "range") {
      setRange(value);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    statusFormSubmitHandler(tablefee, tax, range);
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Please add below data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              onChange={onChangeHandler("tablefee")}
              value={tablefee}
              type="text"
              name="location"
              className="form-control"
              placeholder="Table fee"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="location"
              className="form-control"
              placeholder="KM range (circle range)"
              onChange={onChangeHandler("range")}
              value={range}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="location"
              className="form-control"
              placeholder="Tax (default 13%)"
              onChange={onChangeHandler("tax")}
              value={tax}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
