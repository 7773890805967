import React, { useEffect } from "react";
import Nav from "../Nave/nav";
import Footer from "../Footer/Footer";
import Body from "./components/Body";

import { useSelector, useDispatch } from "react-redux";
import { getUserLocations } from "../../store/location/actions";

export default () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { locationList } = useSelector((state) => state.location);

  useEffect(() => {
    const requestPacket = {
      buyerId: "",
      ownerId: "",
      adminId: `${user && user.userId ? user.userId : ""}`,
    };
    dispatch(getUserLocations(requestPacket));
  }, []);

  return (
    <div>
      {/* Start Nav  */}
      <Nav />
      {/* End Nav */}

      {/* Start Body */}
      <Body locationList={locationList} />
      {/* End Body */}

      {/* Start Footer */}
      <Footer />
      {/* End Footer */}
    </div>
  );
};
