// import React, { useEffect, useRef, useState } from "react";
// // Redux
// import { useSelector, useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
// import { addLocation } from "../../../store/location/actions";
// import { getAllVAR } from "../../../store/var/actions";

// export default () => {
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const inputFile = useRef(null);

//   const { thirdPartyLocations } = useSelector((state) => state.location);
//   const { varList } = useSelector((state) => state.var);
//   // fields state
//   const [thirdpartyLocationId, setThirdpartyLocationId] = useState("");
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   // const [kmRange, setKmRange] = useState('');
//   const [file, setFile] = useState(null);
//   const [image, setImage] = useState("image");
//   // eslint-disable-next-line no-unused-vars
//   const [statusId, setStatusId] = useState(2);
//   // const [tabletFee, setTabletFee] = useState('');
//   // const [tax, setTax] = useState('');
//   const [longitude, setLongitude] = useState("");
//   const [latitude, setLatitude] = useState("");
//   const [locationAddress, setLocationAddress] = useState("");
//   const [city, setCity] = useState("");
//   const [country, setCountry] = useState("");
//   const [state, setState] = useState("");
//   const [userId, setUserId] = useState(null);

//   useEffect(() => {
//     dispatch(getAllVAR());
//   }, []);
//   const addressChangeHandler = (e) => {
//     const { value } = e.target;
//     if (!value || value === "") return;
//     console.log("checking value: ", value);
//     const index = thirdPartyLocations.findIndex(
//       (data) => parseInt(data.id, 10) === parseInt(value, 10)
//     );
//     const long = thirdPartyLocations[index].longitude;
//     const lat = thirdPartyLocations[index].latitude;
//     const address = thirdPartyLocations[index].address;
//     setImage(
//       thirdPartyLocations[index].image_1 && thirdPartyLocations[index].image_1
//     );
//     setThirdpartyLocationId(value);
//     setLongitude(long);
//     setLatitude(lat);
//     setLocationAddress(address);
//     setCity(thirdPartyLocations[index].city);
//     setCountry(thirdPartyLocations[index].country);
//     setState(thirdPartyLocations[index].province);
//   };

//   const onSubmitHandler = (e) => {
//     e.preventDefault();
//     const reqPacket = {
//       title,
//       description,
//       // kmRange,
//       image,
//       statusId,
//       // tabletFee,
//       // tax,
//       thirdpartyLocationId,
//       longitude,
//       latitude,
//       userId,
//       locationAddress,
//       city,
//       country,
//       state: state,
//     };
//     const formData = new FormData();

//     formData.append("request", JSON.stringify(reqPacket));
//     formData.append("file", file);
//     dispatch(addLocation(formData, history));
//   };

//   const imageChangeHandler = (e) => {
//     const file = e.target.files[0];
//     console.log("checking file: ", file);
//     setFile(file);
//   };

//   return (
//     <div>
//       <div className="page7-custom">
//         <div className="page-7 form-container">
//           <form onSubmit={onSubmitHandler}>
//             <div className="form-group">
//               <select
//                 className="form-control"
//                 name="User name"
//                 placeholder="Select VAr"
//                 value={userId ?? ""}
//                 onChange={(e) => setUserId(e.target.value)}
//                 required
//               >
//                 <option value={""}>Select VAR</option>
//                 {varList?.length
//                   ? varList.map((data, idx) => (
//                       <option key={idx} value={data.userId}>
//                         {data?.username} {data?.lastName}
//                       </option>
//                     ))
//                   : ""}
//               </select>
//             </div>
//             <div className="form-group">
//               <select
//                 className="form-control"
//                 name="location"
//                 placeholder="Location addres (google api auto complete)"
//                 value={thirdpartyLocationId}
//                 onChange={addressChangeHandler}
//                 required
//               >
//                 <option value={""}>XP Locations</option>
//                 {thirdPartyLocations && thirdPartyLocations.length
//                   ? thirdPartyLocations.map(
//                       (data, idx) =>
//                         data.status === "active" &&
//                         data.buyin_status === "1" && (
//                           <option
//                             key={idx}
//                             value={data.id}
//                             data-lon={data.longitude}
//                             data-lat={data.latitude}
//                             data-address={data.address}
//                           >
//                             {data.name}
//                           </option>
//                         )
//                     )
//                   : ""}
//               </select>
//             </div>
//             <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Location title"
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//               />
//             </div>
//             {/* <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Table fee"
//               />
//             </div> */}
//             {/* <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="KM range (circle range)"
//               />
//             </div> */}
//             {/* <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Tax (default 13%)"
//               />
//             </div> */}
//             <div className="form-group">
//               <textarea
//                 placeholder="Description"
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <div className="select-location">
//                 <span> {file ? file.name : "Select Location Image"}</span>
//                 <input
//                   id="fileid"
//                   ref={inputFile}
//                   type="file"
//                   name="filename"
//                   hidden
//                   onChange={imageChangeHandler}
//                 />
//                 <input
//                   id="buttonid"
//                   type="button"
//                   name="file"
//                   onClick={() => inputFile.current.click()}
//                   defaultValue="UPLOAD"
//                 />
//               </div>
//             </div>
//             {/* <div className="form-group">
//               <label>Status</label>
//               <select className="form-control">
//                 <option value="select-one">Select One</option>
//                 <option value="select-two">Select Two</option>
//                 <option value="select-three">Select Three</option>
//               </select>
//             </div> */}
//             <div className="form-group">
//               <button type="submit" className="btn btn-register">
//                 SUBMIT
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// import React, { useEffect, useRef, useState } from "react";
// // Redux
// import { useSelector, useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
// import { addLocation } from "../../../store/location/actions";
// import { getAllVAR } from "../../../store/var/actions";
// import axios from "axios";
// import { BACKEND_SERVER_URL } from "../../../config";

// export default () => {
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const inputFile = useRef(null);

//   const { thirdPartyLocations } = useSelector((state) => state.location);
//   const { varList } = useSelector((state) => state.var);
//   console.log(varList);
//   // fields state
//   const [thirdpartyLocationId, setThirdpartyLocationId] = useState("");

//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   // const [kmRange, setKmRange] = useState('');
//   const [file, setFile] = useState(null);
//   const [image, setImage] = useState("image");
//   // eslint-disable-next-line no-unused-vars
//   const [statusId, setStatusId] = useState(2);
//   // const [tabletFee, setTabletFee] = useState('');
//   // const [tax, setTax] = useState('');
//   const [longitude, setLongitude] = useState("");
//   const [latitude, setLatitude] = useState("");
//   const [locationAddress, setLocationAddress] = useState("");
//   const [city, setCity] = useState("");
//   const [country, setCountry] = useState("");
//   const [state, setState] = useState("");
//   const [userId, setUserId] = useState("");
//   const [varLoc, setVarLoc] = useState("");
//   console.log(userId);

//   const fetchLocatiions = async (userId) => {
//     console.log(userId);
//     try {
//       const response = await axios.post(
//         `${BACKEND_SERVER_URL}/getThirdPartyLocations`,
//         { thirdpartyIds: [654] }
//       );
//       console.log(response.data.data);
//       setVarLoc(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     dispatch(getAllVAR());
//     fetchLocatiions(userId);
//   }, []);

//   // Handle user selection change
//   const handleUserSelectChange = (e) => {
//     const selectedUserId = e.target.value;
//     setUserId(selectedUserId);
//     fetchLocatiions(selectedUserId);
//   };

//   const addressChangeHandler = (e) => {
//     const { value } = e.target;
//     if (!value || value === "") return;
//     console.log("checking value: ", value);
//     const index = thirdPartyLocations.findIndex(
//       (data) => parseInt(data.id, 10) === parseInt(value, 10)
//     );
//     const long = thirdPartyLocations[index].longitude;
//     const lat = thirdPartyLocations[index].latitude;
//     const address = thirdPartyLocations[index].address;
//     setImage(
//       thirdPartyLocations[index].image_1 && thirdPartyLocations[index].image_1
//     );
//     setThirdpartyLocationId(value);
//     setLongitude(long);
//     setLatitude(lat);
//     setLocationAddress(address);
//     setCity(thirdPartyLocations[index].city);
//     setCountry(thirdPartyLocations[index].country);
//     setState(thirdPartyLocations[index].province);
//   };

//   const onSubmitHandler = (e) => {
//     e.preventDefault();
//     const reqPacket = {
//       title,
//       description,
//       // kmRange,
//       image,
//       statusId,
//       // tabletFee,
//       // tax,
//       thirdpartyLocationId,
//       longitude,
//       latitude,
//       userId,
//       locationAddress,
//       city,
//       country,
//       state: state,
//     };
//     const formData = new FormData();

//     formData.append("request", JSON.stringify(reqPacket));
//     formData.append("file", file);
//     dispatch(addLocation(formData, history));
//   };

//   const imageChangeHandler = (e) => {
//     const file = e.target.files[0];
//     console.log("checking file: ", file);
//     setFile(file);
//   };

//   return (
//     <div>
//       <div className="page7-custom">
//         <div className="page-7 form-container">
//           <form onSubmit={onSubmitHandler}>
//             <div className="form-group">
//               <select
//                 className="form-control"
//                 name="User name"
//                 placeholder="Select VAr"
//                 value={userId}
//                 onChange={handleUserSelectChange}
//                 required
//               >
//                 <option value={""}>Select VAR</option>
//                 {varList?.length
//                   ? varList.map((data, idx) => (
//                       <option key={idx} value={data.userId}>
//                         {data?.username} {data?.lastName}
//                       </option>
//                     ))
//                   : ""}
//               </select>
//             </div>
//             <div className="form-group">
//               <select
//                 className="form-control"
//                 name="location"
//                 placeholder="Location addres (google api auto complete)"
//                 value={thirdpartyLocationId}
//                 onChange={addressChangeHandler}
//                 required
//               >
//                 <option value={""}>XP Locations</option>
//                 {thirdPartyLocations && thirdPartyLocations.length
//                   ? thirdPartyLocations.map(
//                       (data, idx) =>
//                         data.status === "active" &&
//                         data.buyin_status === "1" && (
//                           <option
//                             key={idx}
//                             value={data.id}
//                             data-lon={data.longitude}
//                             data-lat={data.latitude}
//                             data-address={data.address}
//                           >
//                             {data.name}
//                           </option>
//                         )
//                     )
//                   : ""}
//               </select>
//             </div>
//             <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Location title"
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//               />
//             </div>
//             {/* <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Table fee"
//               />
//             </div> */}
//             {/* <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="KM range (circle range)"
//               />
//             </div> */}
//             {/* <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Tax (default 13%)"
//               />
//             </div> */}
//             <div className="form-group">
//               <textarea
//                 placeholder="Description"
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <div className="select-location">
//                 <span> {file ? file.name : "Select Location Image"}</span>
//                 <input
//                   id="fileid"
//                   ref={inputFile}
//                   type="file"
//                   name="filename"
//                   hidden
//                   onChange={imageChangeHandler}
//                 />
//                 <input
//                   id="buttonid"
//                   type="button"
//                   name="file"
//                   onClick={() => inputFile.current.click()}
//                   defaultValue="UPLOAD"
//                 />
//               </div>
//             </div>
//             {/* <div className="form-group">
//               <label>Status</label>
//               <select className="form-control">
//                 <option value="select-one">Select One</option>
//                 <option value="select-two">Select Two</option>
//                 <option value="select-three">Select Three</option>
//               </select>
//             </div> */}
//             <div className="form-group">
//               <button type="submit" className="btn btn-register">
//                 SUBMIT
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addLocation } from "../../../store/location/actions";
import { getAllVAR } from "../../../store/var/actions";
import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../config";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputFile = useRef(null);

  const { thirdPartyLocations } = useSelector((state) => state.location);
  const { varList } = useSelector((state) => state.var);
  console.log(varList);
  // fields state
  const [thirdpartyLocationId, setThirdpartyLocationId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState("image");
  const [statusId, setStatusId] = useState(2);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [userId, setUserId] = useState("");
  const [varLoc, setVarLoc] = useState("");

  /// fetching the third party locations here ////

  const fetchLocations = async (thirdpartyId) => {
    console.log(thirdpartyId);
    try {
      const response = await axios.post(
        `${BACKEND_SERVER_URL}/getThirdPartyLocations`,
        { thirdpartyIds: [thirdpartyId] }
      );
      console.log(response.data.data.buyin_status);
      if (response.data.data && response.data.data.length > 0) {
        // Filter the data to include only items with status "active"
        const activeLocations = response.data.data.filter(
          (location) => location.buyin_status === "1"
        );

        // Set the filtered data to your state variable
        setVarLoc(activeLocations);

        console.log("Active Locations:", activeLocations);
      } else {
        console.log("No data found.");
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    dispatch(getAllVAR());
  }, []);

  // Handle user selection change
  const handleUserSelectChange = (e) => {
    const selectedUserId = e.target.value;
    setUserId(selectedUserId);
    const selectedUser = varList.find(
      (data) => parseInt(data.userId) === parseInt(selectedUserId)
    );

    const thirdpartyId = selectedUser.thridpartyId;
    console.log("Selected thirdpartyId:", thirdpartyId);
    fetchLocations(thirdpartyId);
  };

  const addressChangeHandler = (e) => {
    const { value } = e.target;
    if (!value || value === "") return;
    const index = thirdPartyLocations.findIndex(
      (data) => parseInt(data.id, 10) === parseInt(value, 10)
    );
    const long = thirdPartyLocations[index].longitude;
    const lat = thirdPartyLocations[index].latitude;
    const address = thirdPartyLocations[index].address;
    setImage(
      thirdPartyLocations[index].image_1 && thirdPartyLocations[index].image_1
    );
    setThirdpartyLocationId(value);
    setLongitude(long);
    setLatitude(lat);
    setLocationAddress(address);
    setCity(thirdPartyLocations[index].city);
    setCountry(thirdPartyLocations[index].country);
    setState(thirdPartyLocations[index].province);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const reqPacket = {
      title,
      description,
      image,
      statusId,
      thirdpartyLocationId,
      longitude,
      latitude,
      userId,
      locationAddress,
      city,
      country,
      state: state,
    };
    const formData = new FormData();

    formData.append("request", JSON.stringify(reqPacket));
    formData.append("file", file);
    dispatch(addLocation(formData, history));
  };

  const imageChangeHandler = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  return (
    <div>
      <div className="page7-custom">
        <div className="page-7 form-container">
          <form onSubmit={onSubmitHandler}>
            <div className="form-group">
              <select
                className="form-control"
                name="User name"
                placeholder="Select VAr"
                value={userId}
                onChange={handleUserSelectChange}
                required
              >
                <option value={""}>Select VAR</option>
                {varList?.length
                  ? varList.map((data, idx) => (
                      <option key={idx} value={data.userId}>
                        {data?.username} {data?.lastName}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="form-group">
              <select
                className="form-control"
                name="location"
                placeholder="Location address (google api auto-complete)"
                value={thirdpartyLocationId}
                onChange={addressChangeHandler}
                required
              >
                <option value={""}>XP Locations</option>
                {varLoc?.length
                  ? varLoc.map((data, idx) => (
                      <option key={idx} value={data.id}>
                        {data.name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="location"
                className="form-control"
                placeholder="Location title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <textarea
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="select-location">
                <span> {file ? file.name : "Select Location Image"}</span>
                <input
                  id="fileid"
                  ref={inputFile}
                  type="file"
                  name="filename"
                  hidden
                  onChange={imageChangeHandler}
                />
                <input
                  id="buttonid"
                  type="button"
                  name="file"
                  onClick={() => inputFile.current.click()}
                  defaultValue="UPLOAD"
                />
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-register">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
