import React from "react";
import Body from "./components/Body";
import Nav from "../Nave/nav";
import Footer from "../Footer/Footer";

export default function index() {
  return (
    <div>
      {/* //Nav Bar */}
      <Nav />
      {/* //End Nav */}

      {/* //Body */}
      <Body />
      {/* End Body */}

      {/* Footer */}
      <Footer />
      {/* End Footer */}
    </div>
  );
}
