import React, { useEffect, useState } from "react";
// import React, { useState, useEffect } from "react";

import MarkerInfoWindow from "./googleMap/gMap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { deleteLocation } from "../../../store/location/actions";

const Body1 = ({ locationList }) => {
  const dispatch = useDispatch();
  const [locationListMap, setLocationListMap] = useState([]);
  useEffect(() => {
    if (locationList && locationList.length) {
      setLocationListMap(locationList);
    }
  }, [locationList]);

  const handleDelete = (locationId) => (e) => {
    e.preventDefault();
    console.log(locationId, "delete click");
    const reqPacket = {
      locationId: locationId,
    };
    dispatch(deleteLocation(reqPacket));
  };
  console.log(locationList);
  console.log(locationListMap);
  return (
    <div>
      <div className="location-container">
        <div className="location-section">
          {locationList.map((data, index) => (
            <div
              key={index}
              className="card "
              style={{ width: "16rem", height: "250px" }}
            >
              <div className="action-container">
                <div className="action-container-2">
                  <div
                    style={{
                      float: "left",
                      backgroundColor: "white",
                      textAlign: "center",
                      padding: "5px 4px",
                      borderRadius: "5px",
                      fontFamily: "Roboto Condensed",
                    }}
                    className="action-type "
                  >
                    {data.statusName}
                  </div>
                  <div className="d-flex ">
                    <span className="action-icon edit-icon mr-1">
                      <Link to="/AddLocation">
                        <svg
                          width="15px"
                          height="15px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                            fill="blue"
                          />
                        </svg>
                      </Link>
                    </span>
                    <span className="action-icon delete-icon ">
                      <Link
                        to="#"
                        className="del-icon "
                        onClick={handleDelete(data?.locationId)}
                      >
                        <svg
                          width="15px"
                          height="15px"
                          viewBox="0 -0.5 21 21"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Dribbble-Light-Preview"
                              transform="translate(-179.000000, -360.000000)"
                              fill="#619f32"
                            >
                              <g
                                id="icons"
                                transform="translate(56.000000, 160.000000)"
                              >
                                <path
                                  d="M130.35,216 L132.45,216 L132.45,208 L130.35,208 L130.35,216 Z M134.55,216 L136.65,216 L136.65,208 L134.55,208 L134.55,216 Z M128.25,218 L138.75,218 L138.75,206 L128.25,206 L128.25,218 Z M130.35,204 L136.65,204 L136.65,202 L130.35,202 L130.35,204 Z M138.75,204 L138.75,200 L128.25,200 L128.25,204 L123,204 L123,206 L126.15,206 L126.15,220 L140.85,220 L140.85,206 L144,206 L144,204 L138.75,204 Z"
                                  id="delete-[#1487]"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <img
                className="card-img-top img-fluid"
                style={{ height: "170px" }}
                src={data.image}
                alt={data.title}
              />
              <Link
                to={`/AdminLocationDetails?locationId=${data.locationId}`}
                style={{ cursor: "pointer", color: "black" }}
              >
                <div className="card-body p-2">
                  <div className="d-flex  justify-content-between">
                    <p className="m-0">{data?.title.substring(0, 15)}</p>
                    <p className="m-0">{data?.tabletFee}</p>
                  </div>
                  <div className="d-flex  justify-content-between">
                    <p className="m-0" style={{ width: "12rem" }}>
                      {data?.locationAddress.substring(0, 20)}
                    </p>
                    <p className="m-0">{data?.kmRange} km</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="location-map">
          {/* {console.log("location maps:", locationListMap)} */}
          <MarkerInfoWindow p={locationListMap} />
        </div>
      </div>
    </div>
  );
};

export default Body1;
