import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Nav from "../Nave/nav";
import Footer from "../Footer/Footer";
import Body from "./components/Body";

import { useSelector, useDispatch } from "react-redux";
import {
  deleteLocation,
  getLocationsByUserId,
} from "../../store/location/actions";
import Body1 from "./components/Body1";

export default function index() {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { locationList } = useSelector((state) => state.location);

  useEffect(() => {
    const reqPacket = {
      userId: Number(userId),
      userDetail: "admin",
    };
    dispatch(getLocationsByUserId(reqPacket));
  }, []);

  // eslint-disable-next-line no-unused-vars
  const deleteLocationHandler = (locationId) => (e) => {
    e.preventDefault();
    const reqPacket = {
      locationId: locationId,
    };
    dispatch(deleteLocation(reqPacket));
  };

  return (
    <div>
      {/* Nav Start */}
      <Nav />
      {/* Nav End */}

      {/* LeftSection */}
      {/* <Body locationList={locationList} /> */}
      <Body1 locationList={locationList} />
      {/* End Left section */}

      {/* Start Footer */}
      <Footer />
      {/* End Footer */}
    </div>
  );
}
