import axios from "axios";
import * as actionTypes from "./types";
import * as pageActions from "../page/actions";
import * as toastActions from "../../components/common/toastify";
import { getThirdPartyLocations } from "../location/actions";
import { BACKEND_SERVER_URL } from "../../config";
import { setDefaultHeader } from "../../utils/setDefaultHeader";

export const getAllVAR = () => async (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .get(BACKEND_SERVER_URL + "/getAllVAR")
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        const thirdpartyIds = [];
        data?.varList?.map((data) => {
          thirdpartyIds.push(data.thridpartyId);
          return data;
        });
        dispatch({
          type: actionTypes.GET_VAR_DATA,
          payload: data.varList,
        });
        dispatch(getThirdPartyLocations(thirdpartyIds));
      } else {
        toastActions.Error("SomeThing Went Wrong!");
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

// export const registerVAR = (reqPacket, history) => (dispatch) => {
//   dispatch(pageActions.setPageLoading());
//   setDefaultHeader(true);
//   console.log(reqPacket);
//   axios
//     .post(BACKEND_SERVER_URL + "/registerVAR", reqPacket)
//     .then((res) => {
//       const { resultCode } = res.data;
//       if (resultCode === "200") {
//         history.push("/AdminVarList");
//         toastActions.Success("Congratulations! Var Successfully Added.");
//       } else {
//         toastActions.Error("SomeThing Went Wrong! Please try again.");
//       }
//     })
//     .catch((err) => {
//       console.log("checking err: ", err);
//       toastActions.Error(
//         err && err.response && err.response.data
//           ? err.response.data.message
//           : "Something went wrong! Please try again."
//       );
//     })
//     .finally(() => dispatch(pageActions.clearPageLoading()));
// };

export const registerVAR = (reqPacket, history) => async (dispatch) => {
  try {
    dispatch(pageActions.setPageLoading());
    setDefaultHeader(true);
    console.log(reqPacket);
    const response = await axios.post(
      BACKEND_SERVER_URL + "/registerVAR",
      reqPacket
    );

    const { resultCode } = response.data;

    if (resultCode === "200") {
      history.push("/AdminVarList");
      toastActions.Success("Congratulations! Var Successfully Added.");
    }
  } catch (error) {
    toastActions.Error("Something Went Wrong! Please try again.", error);
    console.error("Error:", error);

    if (error.response) {
      // Server returned an error response
      const errorMessage =
        error.response.data.message ||
        "Something went wrong! Please try again.";
      toastActions.Error(errorMessage);
    } else if (error.request) {
      // The request was made but no response was received
      toastActions.Error(
        "No response from the server. Please try again later."
      );
    } else {
      // Something happened in setting up the request that triggered an error
      toastActions.Error("An unexpected error occurred. Please try again.");
    }
  } finally {
    dispatch(pageActions.clearPageLoading());
  }
};
