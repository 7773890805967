import axios from "axios";
import * as actionsTypes from "./types";
import * as pageActions from "../page/actions";
import * as toastActions from "../../components/common/toastify";
import { LOCAL_STORAGE_USER, BACKEND_SERVER_URL } from "../../config";

export const setCurrentUser = (decoded) => {
  if (!localStorage[LOCAL_STORAGE_USER]) {
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(decoded));
  }
  return {
    type: actionsTypes.SET_CURRENT_USER,
    payload: decoded,
  };
};

export const clearCurrentUser = () => {
  return {
    type: actionsTypes.CLEAR_CURRENT_USER,
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem(LOCAL_STORAGE_USER);
  dispatch(clearCurrentUser());
};

export const login = (reqPacket, history) => async (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post("https://xp.life:8443/eats/apistore/authenticateAdminUser", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        console.log("checking data: ", data);
        dispatch(setCurrentUser(data));
        history.push(`/dashboard`);
        toastActions.Success("Successfully logged in!.");
      } else {
        toastActions.Error("SomeThing Went Wrong! While trying to login");
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const updateUserInfo = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/updateUserInfo", reqPacket)
    .then((res) => {
      const { status, data } = res.data;
      if (status === "success") {
        dispatch(setCurrentUser(data.user));
        toastActions.Success("User info successfully updated!.");
      } else {
        toastActions.Error("SomeThing Went Wrong! Please try again.");
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const verifyUser = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/verifyUser", reqPacket)
    .then((res) => {
      const { status, data } = res.data;
      if (status === "success") {
        dispatch(setCurrentUser(data.user));
        toastActions.Success("Email successfully verified!.");
      } else {
        toastActions.Error("SomeThing Went Wrong! Please try again.");
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const updatePassword = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/updatePassword", reqPacket)
    .then((res) => {
      const { status } = res.data;
      if (status === "success") {
        toastActions.Success("password successfully updated!.");
      } else {
        toastActions.Error("SomeThing Went Wrong! Please try again.");
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      toastActions.Error(
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong! Please try again."
      );
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
