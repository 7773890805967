import React from "react";
import GoogleMapReact from "google-map-react";
import { Marker } from "../../../components/common/singleMarker";
// import { Marker } from "../AdminLocationListView/components/googleMap/singleMarker";
export default function Body({
  statusesList,
  locationDetail,
  onStatusChangeHandler,
}) {
  const defaultProps = {
    zoom: 16,
  };
  const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const lat = Number(locationDetail.latitude);
  const lng = Number(locationDetail.longitude);
  return (
    <div>
      <div className="page6-custom">
        <div className="page-6-container">
          <div className="home-top-section mb-2">
            <div className="home-t">
              {locationDetail.title && locationDetail.title}
            </div>
            <div className="home-price">
              <select
                value={locationDetail.statusId && locationDetail.statusId}
                onChange={onStatusChangeHandler}
              >
                {statusesList && statusesList.length
                  ? statusesList.map((data, idx) => (
                      <option key={idx} value={data.id && data.id}>
                        {data.name && data.name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
          </div>
          <div className="home-address-details">
            <div className="home-address-desc">
              Address:
              {locationDetail.locationAddress && locationDetail.locationAddress}
            </div>
            <div className="home-distance">
              <span className="price me-3">
                ${locationDetail.tabletFee && locationDetail.tabletFee} CAD
              </span>
              <span className="location">
                {locationDetail.kmRange && locationDetail.kmRange}KM
              </span>
            </div>
          </div>
          <div className="house-detail-banner">
            {/* <img src="images/house-detail.jpeg" alt="house" /> */}
            <img src={locationDetail.image} alt="house" />
          </div>
          <div className="house-detail-description">
            <div className="t-t-t">Description</div>
            <p className="desc-p">
              {locationDetail.description && locationDetail.description}
            </p>
          </div>
          <div className="house-map">
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3400.4690277872905!2d74.33367485022342!3d31.538740281271977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904eaa5532d49%3A0x5854b478352230e6!2sJail%20Road%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1616831884286!5m2!1sen!2s"
              title="map"
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            /> */}
            {locationDetail && (
              <div
                className="your-element"
                style={{ position: "relative", height: "350px", width: "100%" }}
              >
                {lat && lng && (
                  <GoogleMapReact
                    style={{ border: 0 }}
                    bootstrapURLKeys={{ key: googleMapKey }}
                    center={{
                      lat: lat,
                      lng: lng,
                    }}
                    defaultZoom={defaultProps.zoom}
                  >
                    <Marker
                      lat={lat}
                      lng={lng}
                      text="http://maps.google.com/mapfiles/ms/icons/red.png"
                    />
                  </GoogleMapReact>
                )}
                {/* <div className="cart-btn-container">
              <button type="button" className="btn btn-success btn-block">
                ADD TO CART
              </button>
            </div> */}
              </div>
            )}
            <div className="cart-btn-container">
              {/* <button type="button" className=" btn btn-danger btn-block">
                ADD TO CART
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
