// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import LoaderComponent from "../../common/Loader";
// import { getDashboardData } from "../../../store/dashboard/actions";
// const Body = () => {
//   const dispatch = useDispatch();
//   const { loading } = useSelector((state) => state.page);
//   const { dashboardData } = useSelector((state) => state.dashboard);
//   const { user } = useSelector((state) => state.auth);

//   const reqPacket = {
//     userId: user.userId,
//     roleId: 6,
//   };
//   useEffect(() => {
//     dispatch(getDashboardData(reqPacket));
//   }, []);

//   return (
//     <div>
//       {loading ? (
//         <LoaderComponent />
//       ) : (
//         <div className="page-3-container">
//           {dashboardData &&
//             dashboardData.dashboardBuyInSummary &&
//             dashboardData.dashboardBuyInSummary.map((t, index) => {
//               return (
//                 <div key={index} className="single-location">
//                   <div className="t-title">{t.label}</div>
//                   <div className="t-info">
//                     <div className="number">{t.value}</div>
//                     <img src="images/032-boy-13.svg" alt="032-boy-13" />
//                   </div>
//                 </div>
//               );
//             })}

//           <div className="income-option">
//             {dashboardData &&
//               dashboardData.dashboardBuyInTiles &&
//               dashboardData.dashboardBuyInTiles.map((s, index) => {
//                 return (
//                   <div key={index} className="income-part">
//                     <div className="income-part-t">
//                       {s.heading}

//                       <span className="income-intro">{s.summary}</span>
//                       {/* <div className="income-price">{s.summaryValue}</div> */}
//                     </div>

//                     <div className="income-exam">
//                       <div className="income-intro">{s.description}</div>
//                       <div className="income-price">{s.value}</div>
//                     </div>
//                   </div>
//                 );
//               })}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
// export default Body;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../common/Loader";
import { getDashboardData } from "../../../store/dashboard/actions";
import notFound from "../../../images/ic_not_found.svg";

const Body = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.page);
  const { dashboardData } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);

  const reqPacket = {
    userId: user.userId,
    roleId: 6,
  };

  useEffect(() => {
    dispatch(getDashboardData(reqPacket));
  }, []);

  console.log("dashboard data:", dashboardData);

  return (
    <div>
      {loading ? (
        <LoaderComponent />
      ) : dashboardData ? (
        <div className="page-3-container">
          {dashboardData.dashboardBuyInSummary &&
            dashboardData.dashboardBuyInSummary.map((t, index) => {
              return (
                <div key={index} className="single-location">
                  <div className="t-title">{t.label}</div>
                  <div className="t-info">
                    <div className="number">{t.value}</div>
                    <img src="images/032-boy-13.svg" alt="032-boy-13" />
                  </div>
                </div>
              );
            })}

          <div className="income-option">
            {dashboardData.dashboardBuyInTiles &&
              dashboardData.dashboardBuyInTiles.map((s, index) => {
                return (
                  <div key={index} className="income-part">
                    <div className="income-part-t">
                      {s.heading}
                      <span className="income-intro">{s.summary}</span>
                    </div>
                    <div className="income-exam">
                      <div className="income-intro">{s.description}</div>
                      {/* <div className="income-price">{s.value}</div> */}
                      <div className="income-price">
                        {s.value !== "$null" ? `${s.value}` : "$0"}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div>
          <img src={notFound} alt="not found image" />
        </div>
      )}
    </div>
  );
};

export default Body;
