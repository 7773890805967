// /* eslint-disable prettier/prettier */
// import React, { useEffect, useState } from "react";
// import { Link, useHistory } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { getVARLocDetailList } from "../../store/location/actions";
// import MarkerInfoWindow from "./components/googleMap/gMap";
// import Nav from "../Nave/nav";
// import VarDetailModal from "../../components/layout/modal";
// import { Pagination } from "react-bootstrap";

// export default () => {
//   const dispatch = useDispatch();
//   const { varDetailList, locationList } = useSelector(
//     (state) => state.location
//   );
//   const history = useHistory();
//   const [show, setShow] = useState(false);
//   const [varDetail, setVarDetail] = useState({});
//   const [locationListMap, setLocationListMap] = useState([]);
//   useEffect(() => {
//     dispatch(getVARLocDetailList());
//   }, []);

//   const handleClose = () => setShow(false);
//   const handleShow = (id) => {
//     const updatedVarDetail = varDetailList.filter((data) => data.userId === id);
//     setVarDetail(updatedVarDetail[0]);
//     setShow(true);
//   };

//   useEffect(() => {
//     if (varDetailList && varDetailList.length) {
//       let allLocationList = [];
//       for (const data of varDetailList) {
//         allLocationList = [...allLocationList, ...data.locationsList];
//       }
//       setLocationListMap(allLocationList);
//     }
//   }, [varDetailList]);

//   useEffect(() => {
//     if (locationList && locationList.length) {
//       setLocationListMap(locationList);
//     }
//   }, [locationList]);

//   const onAddLocation = (userId) => {
//     history.push(`/location-${userId}`);
//   };

//   const handleGetLocations = (index) => {
//     if (varDetailList && varDetailList[index]) {
//       setLocationListMap(varDetailList[index].locationsList ?? []);
//     }
//   };

//   return (
//     <div>
//       <Nav />
//       <VarDetailModal
//         show={show}
//         handleClose={handleClose}
//         varDetail={varDetail}
//       />
//       <div className="location-container page-12-location">
//         <div className="location-section">
//           {varDetailList &&
//             varDetailList.map((card, index) => (
//               <div key={index} className="detail-info-location">
//                 <div className="user-main-container">
//                   <div className="user-main">
//                     <div className="user-dp-text">
//                       {card.userImage &&
//                       card.userImage !== "image" &&
//                       card.userImage !== "" ? (
//                         <img
//                           style={{ width: "85px", height: "85px" }}
//                           src={card.userImage}
//                           alt="var Profile"
//                           onClick={() => handleShow(card.userId)}
//                         />
//                       ) : (
//                         <img
//                           src="images/ic_var.png"
//                           alt="Var Profile"
//                           onClick={() => handleShow(card.userId)}
//                         />
//                       )}
//                       <div className="details-t">
//                         <div className="user-details-t">
//                           <span className="text-b">Full Name: </span>
//                           <span>{card.fullName}</span>
//                         </div>
//                         <div className="user-details-t">
//                           <span className="text-b">Username: </span>
//                           <span>{card.userName}</span>
//                         </div>
//                         <div className="user-details-t">
//                           <span
//                             className="text-b"
//                             style={{
//                               cursor: "pointer",
//                               color: "blue",
//                             }}
//                             onClick={() => handleGetLocations(index)}
//                           >
//                             Locations
//                           </span>
//                         </div>
//                         {/* <div className="user-details-t">
//                           <span className="text-b">Contact Number: </span>
//                           <span>{card.contactNumber}</span>
//                         </div> */}
//                       </div>
//                     </div>
//                     <div style={{ flex: 1, textAlign: "end" }}>
//                       <div style={{ minWidth: "90px" }}>
//                         {card.locationCount} Available
//                       </div>
//                       <div style={{ minWidth: "90px" }}>
//                         {card.locationBooked ?? 0} Booked
//                       </div>
//                     </div>
//                   </div>
//                   <div className="user-gallery" style={{ gap: "7px" }}>
//                     {card.locationsList &&
//                       card.locationsList
//                         .slice(0, 5)
//                         .map((locationItem, idx) => (
//                           <Link
//                             key={idx}
//                             to={`/AdminLocationDetails?locationId=${locationItem.locationId}`}
//                           >
//                             <img
//                               style={{ marginRight: "none" }}
//                               src={
//                                 locationItem.locationImage !== "image" &&
//                                 locationItem.locationImage !== ""
//                                   ? locationItem.locationImage
//                                   : "images/ic_var.png"
//                               }
//                             />
//                           </Link>
//                         ))}
//                     <span
//                       style={{ cursor: "pointer" }}
//                       className="plus-sign"
//                       onClick={() => onAddLocation(card.userId)}
//                     >
//                       <i className="fa fa-plus" aria-hidden="true" />
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           {/* <div className="var_location_pagination">
//             <Pagination className="pagination">
//               <Pagination.First />
//               <Pagination.Prev />
//               <Pagination.Item>{1}</Pagination.Item>
//               <Pagination.Ellipsis />

//               <Pagination.Item>{10}</Pagination.Item>
//               <Pagination.Item active>{1}</Pagination.Item>
//               <Pagination.Item disabled>{14}</Pagination.Item>

//               <Pagination.Ellipsis />
//               <Pagination.Item>{20}</Pagination.Item>
//               <Pagination.Next />
//               <Pagination.Last />
//             </Pagination>
//           </div> */}
//         </div>

//         <div className="location-map">
//           <MarkerInfoWindow p={locationListMap} />
//         </div>
//       </div>
//     </div>
//   );
// };

/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getVARLocDetailList } from "../../store/location/actions";
import MarkerInfoWindow from "./components/googleMap/gMap";
import Nav from "../Nave/nav";
import VarDetailModal from "../../components/layout/modal";
import { Pagination } from "react-bootstrap";

export default () => {
  const dispatch = useDispatch();
  const { varDetailList, locationList } = useSelector(
    (state) => state.location
  );
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [varDetail, setVarDetail] = useState({});
  const [filteredVarDetailList, setFilteredVarDetailList] = useState([]);
  const [locationListMap, setLocationListMap] = useState([]);
  useEffect(() => {
    dispatch(getVARLocDetailList());
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = (id) => {
    const updatedVarDetail = varDetailList.find((data) => data.userId === id);
    setVarDetail(updatedVarDetail || {});
    setShow(true);
  };

  useEffect(() => {
    if (varDetailList && varDetailList.length) {
      let allLocationList = [];
      for (const data of varDetailList) {
        allLocationList = [...allLocationList, ...data.locationsList];
      }
      setLocationListMap(allLocationList);
    }
  }, [varDetailList]);

  useEffect(() => {
    if (locationList && locationList.length) {
      setLocationListMap(locationList);
    }
  }, [locationList]);

  useEffect(() => {
    const filteredList = varDetailList.filter((card) => card.locationCount > 0);

    console.log(filteredList, "filter lost");
    setFilteredVarDetailList(filteredList);
  }, [varDetailList]);

  const onAddLocation = (userId) => {
    history.push(`/location-${userId}`);
  };

  const handleGetLocations = (index) => {
    if (filteredVarDetailList && filteredVarDetailList[index]) {
      setLocationListMap(filteredVarDetailList[index].locationsList || []);
    }
  };
  return (
    <div>
      <Nav />
      <VarDetailModal
        show={show}
        handleClose={handleClose}
        varDetail={varDetail}
      />
      <div className="location-container page-12-location">
        <div className="location-section">
          {filteredVarDetailList &&
            filteredVarDetailList.map((card, index) => (
              <div key={index} className="detail-info-location">
                <div className="user-main-container">
                  <div className="user-main">
                    <div className="user-dp-text">
                      {card.userImage &&
                      card.userImage !== "image" &&
                      card.userImage !== "" ? (
                        <img
                          style={{ width: "85px", height: "85px" }}
                          src={card?.userImage}
                          alt="var Profile"
                          onClick={() => handleShow(card.userId)}
                        />
                      ) : (
                        <img
                          src="images/ic_var.png"
                          alt="Var Profile"
                          onClick={() => handleShow(card.userId)}
                        />
                      )}
                      <div className="details-t">
                        <div className="user-details-t">
                          <span className="text-b">Full Name: </span>
                          <span>{card.fullName}</span>
                        </div>
                        <div className="user-details-t">
                          <span className="text-b">Username: </span>
                          <span>{card.userName}</span>
                        </div>
                        <div className="user-details-t">
                          <span
                            className="text-b"
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                            onClick={() => handleGetLocations(index)}
                          >
                            Locations
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: 1, textAlign: "end" }}>
                      <div style={{ minWidth: "90px" }}>
                        {card.locationCount} Available
                      </div>
                      <div style={{ minWidth: "90px" }}>
                        {card.locationBooked ?? 0} Booked
                      </div>
                    </div>
                  </div>
                  <div className="user-gallery" style={{ gap: "7px" }}>
                    {card.locationsList &&
                      card.locationsList
                        .slice(0, 5)
                        .map((locationItem, idx) => (
                          <Link
                            key={idx}
                            to={`/AdminLocationDetails?locationId=${locationItem.locationId}`}
                          >
                            <img
                              style={{ marginRight: "none" }}
                              src={
                                locationItem.locationImage !== "image" &&
                                locationItem.locationImage !== ""
                                  ? locationItem.locationImage
                                  : "images/ic_var.png"
                              }
                            />
                          </Link>
                        ))}
                    <span
                      style={{ cursor: "pointer" }}
                      className="plus-sign"
                      onClick={() => onAddLocation(card.userId)}
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          {/* Pagination */}
          <div className="var_location_pagination">
            <Pagination className="pagination">
              {/* Include your pagination items here */}
            </Pagination>
          </div>
        </div>

        <div className="location-map">
          <MarkerInfoWindow p={locationListMap} />
        </div>
      </div>
    </div>
  );
};
