import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerVAR } from "../../../store/var/actions";

export default function Form() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.page);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currency, setCurrency] = useState("CAD");
  const [file, setFile] = useState("");

  const history = useHistory();
  const varSchema = Yup.object().shape({
    firstName: Yup.string().required("* first_name is required"),
    lastName: Yup.string().required("* last_name is required"),
    userName: Yup.string().required("* username is required"),
    emailId: Yup.string().required("* email is required"),
    contactNumber: Yup.string().required("* contactNumber is required"),
    commission: Yup.string().required("* commission is required"),
    password: Yup.string().required("* password is required"),
    confirmPassword: Yup.string().required("* confirm_password is required"),
    image: Yup.string().required("* image is required"),
    roleId: Yup.number().required("* image is required"),
    countryId: Yup.number().required("* image is required"),
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const _file = e.target.files[0];
    reader.onloadend = () => {
      setFile(_file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(_file);
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    delete values.image;
    console.log("checking values: ", values);
    formData.append("request", JSON.stringify(values));
    formData.append("file", file);
    dispatch(registerVAR(formData, history));
  };

  return (
    <div>
      <div className="page7-custom">
        <div className="page-7 form-container">
          <div className="logo">
            <img src="images/xp_eats.svg" width="100px" alt="XP Eats" />
          </div>
          <div className="form-title">MOBILE DELIVERY FRANCHISE</div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              userName: "",
              emailId: "",
              contactNumber: "",
              commission: "",
              password: "",
              confirmPassword: "",
              image: "image",
              roleId: 5,
              countryId: 1,
              currency,
            }}
            validationSchema={varSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                `{" "}
                <div className="name-img-section">
                  <div className="user-img">
                    {/* <img src="images/dp.jpeg" alt="dp" /> */}
                    {/* <div className="App"></div> */}
                    <label
                      // htmlFor="photo-upload"
                      style={{ height: "100%" }}
                      className="custom-file-upload"
                    >
                      <div style={{ height: "100%" }}>
                        <img
                          style={{ height: "100%" }}
                          alt=""
                          // htmlFor="photo-upload"
                          src={imagePreviewUrl || "images/ic_placeholder.svg"}
                        />
                      </div>
                      <input
                        id="photo-upload"
                        type="file"
                        onChange={photoUpload}
                      />
                    </label>
                  </div>
                  <div className="first-last-name-section">
                    <div className="form-group">
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                    </div>
                    {errors.firstName && touched.firstName ? (
                      <div style={{ color: "red" }}>{errors.firstName}</div>
                    ) : null}
                    <div className="form-group">
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                    </div>
                    {errors.lastName && touched.lastName ? (
                      <div style={{ color: "red" }}>{errors.lastName}</div>
                    ) : null}
                  </div>
                  `
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="userName"
                    className="form-control"
                    placeholder="Username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                  />
                </div>
                {errors.userName && touched.userName ? (
                  <div style={{ color: "red" }}>{errors.userName}</div>
                ) : null}
                <div className="form-group">
                  <input
                    type="email"
                    name="emailId"
                    className="form-control"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailId}
                  />
                </div>
                {errors.emailId && touched.emailId ? (
                  <div style={{ color: "red" }}>{errors.emailId}</div>
                ) : null}
                <div className="form-group country-numbers">
                  <input
                    className="form-control form-control-solid "
                    type="number"
                    placeholder="(617) 397 - 8483"
                    name="contactNumber"
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactNumber}
                  />
                  <span className="country-code">
                    <img src="images/053-canada.svg" alt="053-canada" /> +1
                  </span>
                </div>
                {errors.contactNumber && touched.contactNumber ? (
                  <div style={{ color: "red" }}>{errors.contactNumber}</div>
                ) : null}
                <div className="form-group">
                  <select
                    id="currency"
                    name="currency"
                    className="form-control"
                    defaultValue={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="CAD">CAD</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="commission"
                    className="form-control"
                    placeholder="Commissions Default 10%"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.commission}
                  />
                </div>
                {errors.commission && touched.commission ? (
                  <div style={{ color: "red" }}>{errors.commission}</div>
                ) : null}
                <div className="form-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="form-control"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="show-pass"
                  >
                    {showPassword ? "HIDE" : "SHOW"}
                  </span>
                </div>
                {errors.password && touched.password ? (
                  <div style={{ color: "red" }}>{errors.password}</div>
                ) : null}
                <div className="form-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    className="form-control"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                  />
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="show-pass"
                  >
                    {showConfirmPassword ? "HIDE" : "SHOW"}
                  </span>
                </div>
                {errors.confirmPassword && touched.confirmPassword ? (
                  <div style={{ color: "red" }}>{errors.confirmPassword}</div>
                ) : null}
                <div className="form-group">
                  <button type="submit" className="btn btn-register">
                    {loading ? "loading" : "REGISTER"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
