// import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
// import VarDetailModel from "../../../components/layout/modal";
// // import ApprovedStatusForm from "../../AdminLocationDetails/components/approvedStatusForm";
// // import {
// //   getStatusByType,
// //   getLocationDetail,
// //   updateLocationStatus,
// // } from "../../../store/location/actions";
// // eslint-disable-next-line no-unused-vars
// import { useSelector, useDispatch } from "react-redux";
// export default ({ locationList }) => {
//   // const dispatch = useDispatch();
//   const history = useHistory();
//   const [show, setShow] = useState(false);
//   // const [showApprovedStatus, setShowApprovedStatus] = useState(false);
//   // eslint-disable-next-line no-unused-vars
//   const [varDetail, setVarDetail] = useState({});
//   const { statusesList, locationDetail } = useSelector(
//     (state) => state.location
//   );
//   const handleClose = () => setShow(false);
//   // const handleShow = (id) => {
//   //   const updatedVarDetail = locationList.filter((data) => data.id === id);
//   //   // const reqPacket = {
//   //   //   // userId: Number(updatedVarDetail[0].),
//   //   //   userDetail: "admin",
//   //   // };
//   //   // dispatch(getLocationsByUserId(reqPacket));
//   //   // const selectedVarDetail = {
//   //   //   userImage: "",
//   //   //   fullName: "",
//   //   //   userName: "",
//   //   //   contactNumber: "",
//   //   //   locationsList: "",
//   //   //   description: "",
//   //   //   youtubeLink: "",
//   //   // };
//   //   setVarDetail(updatedVarDetail[0]);
//   //   setShow(true);
//   // };
//   return (
//     <div>
//       <VarDetailModel
//         show={show}
//         handleClose={handleClose}
//         varDetail={varDetail}
//       />
//       <div className="page11-custom">
//         <div
//           style={{ maxWidth: "700px", overflowX: "scroll" }}
//           className="page-11 form-container"
//         >
//           <table>
//             <thead>
//               <tr>
//                 <th>Product</th>
//                 <th>Earning</th>
//                 <th>Username</th>
//                 <th>Email</th>
//                 <th>Phone</th>
//                 <th>Buyer</th>
//                 <th>Device Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {locationList && locationList.length
//                 ? locationList.map((data, idx) => (
//                     <tr key={idx}>
//                       <td className="img-text-td">
//                         <div className="d-flex">
//                           <img
//                             onClick={() =>
//                               history.push(
//                                 `/AdminLocationDetails?locationId=${data.id}`
//                               )
//                             }
//                             // onClick={() => handleShow(data.id)}
//                             style={{ cursor: "pointer" }}
//                             src={
//                               data.locationImage
//                                 ? data.locationImage
//                                 : "images/single-location.jpg"
//                             }
//                             alt=""
//                           />
//                           <div className="img-text-content-part">
//                             <div
//                               style={{ cursor: "pointer" }}
//                               onClick={() =>
//                                 history.push(
//                                   `/AdminLocationDetails?locationId=${data.id}`
//                                 )
//                               }
//                               // onClick={() => handleShow(data.id)}
//                             >
//                               {data.locationTitle && data.locationTitle}
//                             </div>
//                             <div>
//                               {data.locationDescription &&
//                                 data.locationDescription}
//                             </div>
//                             <div>Date: {data.buyDate && data.buyDate}</div>
//                           </div>
//                         </div>
//                       </td>
//                       <td className="price-td">{data.userEarning ?? 0}</td>
//                       <td>{data.userEarning ?? 0}</td>
//                       <td>{data.userEarning ?? 0}</td>
//                       <td>{data.userEarning ?? 0}</td>
//                       <td className="buyer-td">
//                         {data.buyerFirstName && data.buyerFirstName}{" "}
//                         {data.buyerLastName && data.buyerLastName}
//                       </td>
//                       <td className="booked-td">
//                         <select
//                           value={
//                             locationDetail.statusId && locationDetail.statusId
//                           }
//                           // onChange={onStatusChangeHandler}
//                         >
//                           {statusesList && statusesList.length
//                             ? statusesList.map((data, idx) => (
//                                 <option key={idx} value={data.id && data.id}>
//                                   {data.name && data.name}
//                                 </option>
//                               ))
//                             : ""}
//                         </select>
//                         {/* {data.status && data.status} */}
//                       </td>
//                     </tr>
//                   ))
//                 : ""}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import VarDetailModel from "../../../components/layout/modal";
import { useSelector } from "react-redux";
import NoData from "../../../images/ic_not_found.svg";

export default ({ locationList }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [varDetail, setVarDetail] = useState({});
  const { statusesList, locationDetail } = useSelector(
    (state) => state.location
  );
  console.log("location List Data", locationList);
  const handleClose = () => setShow(false);

  return (
    <div>
      <VarDetailModel
        show={show}
        handleClose={handleClose}
        varDetail={varDetail}
      />

      <div className="page11-custom">
        <div className="page-11 form-container text-center">
          {locationList && locationList.length === 0 ? (
            // Render a message when locationList is empty
            <div>
              <img src={NoData} alt="" />
            </div>
          ) : (
            <div className="row">
              {locationList &&
                locationList.length &&
                locationList.map((data, idx) => (
                  <div className="col-md-4 " key={idx}>
                    <div className="card m-2">
                      <img
                        onClick={() =>
                          history.push(
                            `/AdminLocationDetails?locationId=${data.id}`
                          )
                        }
                        src={data.locationImage || "images/single-location.jpg"}
                        alt=""
                        className="card-img-top"
                      />
                      <div className="status-card m-0 p-2 ">
                        <h5
                          onClick={() =>
                            history.push(
                              `/AdminLocationDetails?locationId=${data.id}`
                            )
                          }
                          className="card-title m-0 p-0"
                        >
                          {data.locationTitle}
                        </h5>
                        <p className="card-text my-1 p-0">
                          {/* {data.locationDescription} */}
                          {data.locationDescription &&
                            data.locationDescription.substring(0, 90)}
                        </p>
                        <p className=" m-0 p-0  font-weight-bold">
                          Date: <small>{data.buyDate}</small>
                        </p>
                        <p className="price-td m-0 p-0  font-weight-bold">
                          {data.userEarning ?? 0}
                        </p>
                        <p className="booked-td my-3">
                          {/* <select
                              value={locationDetail.statusId || ""}
                              // onChange={onStatusChangeHandler}
                              className="form-select"
                            >
                              {statusesList &&
                                statusesList.length &&
                                statusesList.map((status, statusIdx) => (
                                  <option key={statusIdx} value={status.id || ""}>
                                    {status.name}
                                  </option>
                                ))}
                            </select> */}
                          <span className=" border px-2 py-1 rounded">
                            {data?.status}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
