import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../common/Loader";
import { getContactUsData } from "../../../store/contactUs/actions";
import NoData from "../../../images/ic_not_found.svg";
export default function Body() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.page);
  const { contactedList } = useSelector((state) => state.contact);
  useEffect(() => {
    dispatch(getContactUsData());
  }, []);

  return (
    <div>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div className="page-custom">
          <div className="page-4-container page-6">
            {contactedList.length === 0 ? (
              // Render a message when the contactedList is empty
              <div className="no-data-message">
                <img src={NoData} alt="" />
              </div>
            ) : (
              // Render the list of contacted users
              contactedList.map((dat, index) => (
                <div key={index} className="user-detail">
                  <div className="user-info">
                    <div className="user-img">
                      <img src={dat.image} alt="user" />
                    </div>
                    <div className="user-description">
                      <div className="user-top-details">
                        <div className="user-t">{dat.userName}</div>
                        <div className="user-icon">
                          <i className="fa fa-ellipsis-v" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="user-specs">
                        <div className="user-email">
                          {dat.subject} <span className="the-dot">.</span>
                          <span className="just-now">{dat.timeAgo}</span>
                        </div>
                        <div className="user-price"></div>
                      </div>
                    </div>
                  </div>
                  <div className="user-info-explaination">
                    <div className="user-main-para">{dat.description}</div>
                  </div>
                </div>
              ))
            )}

            {/* {contactedList &&
              contactedList.map((dat, index) => {
                return (
                  <div key={index} className="user-detail">
                    <div className="user-info">
                      <div className="user-img">
                        <img src={dat.image} alt="user" />
                      </div>
                      <div className="user-description">
                        <div className="user-top-details">
                          <div className="user-t">{dat.userName}</div>
                          <div className="user-icon">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                        <div className="user-specs">
                          <div className="user-email">
                            {dat.subject} <span className="the-dot">.</span>
                            <span className="just-now">{dat.timeAgo}</span>
                          </div>
                          <div className="user-price"></div>
                        </div>
                      </div>
                    </div>
                    <div className="user-info-explaination">
                      <div className="user-main-para">{dat.description}</div>
                    </div>
                  </div>
                );
              })} */}
          </div>
        </div>
      )}
    </div>
  );
}
