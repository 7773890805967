import * as actionTypes from "./types";

const INITIAL_STATE = {
  contactedList: [],
};

const contactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTACT_US_DATA:
      return { ...state, contactedList: action.payload, loading: false };

    default:
      return state;
  }
};
export default contactReducer;
