import React from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser, setCurrentUser } from "./store/auth/actions";
import { privateRoutes, publicRoutes } from "./routes";
import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoute";
import { LOCAL_STORAGE_USER } from "./config";
import Auth from "./pages/Auth";
import Index from "./pages";

const App = () => {
  const dispatch = useDispatch();

  if (localStorage[LOCAL_STORAGE_USER]) {
    dispatch(setCurrentUser(JSON.parse(localStorage[LOCAL_STORAGE_USER])));
  } else {
    dispatch(logoutUser());
  }
  return (
    <Switch>
      {publicRoutes.map((route, idx) => (
        <PublicRoute exact key={idx} path={`${route}`} component={Auth} />
      ))}
      {privateRoutes.map((route, idx) => (
        <PrivateRoute exact key={idx} path={`${route}`} component={Index} />
      ))}
      <Redirect to="/dashboard" />
    </Switch>
  );
};

export default withRouter(App);
