import * as actionTypes from "./types";

const INITIAL_STATE = {
  varList: [],
};

const varReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_VAR_DATA:
      return { ...state, varList: action.payload };
    default:
      return state;
  }
};
export default varReducer;
