import React from "react";
import Nav from "../Nave/nav";
import Footer from "../Footer/Footer";
import Form from "./components/Form";

export default function Index() {
  return (
    <div>
      {/* Start Nav */}
      <Nav />
      {/* End Nav */}

      {/* Start Form */}
      <Form />
      {/* End Form */}

      {/* Start Footer */}
      <Footer />
      {/* End Footer */}
    </div>
  );
}
